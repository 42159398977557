<template>
	<div class="page-container">
		<Head />
		<banner :banner="slidePic" :slide="slide"></banner>
		<!-- <div class="page-banner" :style="{backgroundImage:'url('+(slidePic?slidePic[0]:banner)+')'}">
			<div class="container banner-align-right">
				<div class="banner-text wow fadeInDown">
					<div class="ch">{{lang=='zh-cn'?slide.title:slide.title_en}}</div>
					<div class="en">{{lang=='zh-cn'?slide.titile_comment:slide.titile_comment_en}}</div>
					<img v-if="slide.title" src="../assets/image/down-arrow.png" alt="" /> 
				</div>
			</div>
		</div> -->
		<div class="page-wrapper">
			<div class="container">
				<Content :pagecontent="thispage.items"/>							
			</div>
		</div>
		<Foot />
	</div>
</template>

<script>
	// @ is an alias to /src
	import Head from '@/components/Head.vue'
	import Foot from '@/components/Foot.vue'
	import banner from '@/components/banner.vue'
	import Content from '../components/content.vue';
	import { mapState } from 'vuex';
	export default {
		name: 'Competencies',
		components: {
			Head,
			Foot,
			banner,
			Content
		},
		data() {
			return {
				banner:require('../assets/image/serve_banner.jpg')
			};
		},
		computed:{
			...mapState({
				'lang':state=>state.app.currentLang,
				'webcontents':state=>state.app.webcontents,
				'current':state=>state.app.current
			}),
			thispage(){
				if(!this.current){
					var vid = this.webcontents.list.filter(item=>item.type == 'Competencies')[0].id
					// this.setCurrent(vid)
					return this.webcontents.list.filter(item=>item.id == vid)[0]
				}else{
					return this.webcontents.list.filter(item=>item.id == this.current )[0]
				}
			},
			slide(){
				let slide1 = null
				if(this.thispage){
					slide1 = this.thispage.items.filter(ite=>ite.item_type =='slideshow')
				}
				return (slide1 && slide1.length > 0) ?slide1[0]:'';
			},
			slidePic(){
				let slide1 = null
				if(this.thispage){
					slide1 = this.thispage.items.filter(ite=>ite.item_type =='slideshow')
				}
				return (slide1 && slide1.length > 0) ?slide1[0].pictures.split(','):null;
			},
			// pageitems(){
			// 	var itemlist=this.thispage.items.filter(ite=>ite.item_type !='slideshow')
			// 	var items = []
			// 	var ob = {}
			// 	var pic = []
			// 	var title, ltitle, content, titile_comment
			// 	for (let it of itemlist){
			// 		// console.log(JSON.stringify(it))
			// 		// if(it.item_type =='Three_column'){
			// 		// 	ltitle = this.lang == 'en-US'?it.ltitle_en.split("`"):it.ltitle.split("`")
			// 		// 	content = this.lang == 'en-US'?it.content_en.split("`"):it.content.split("`")
			// 		// // }else if(it.item_type !='picture' && it.item_type !='analysis'&&it.item_type !='onlytitle'){
			// 		// }else if( it.item_type !='analysis'&&it.item_type !='onlytitle'){
			// 		// 	// console.log(JSON.stringify(it))
			// 		// 	if(this.lang == 'en-US' &&it.content_en !='null'&&it.content_en){
			// 		// 		content = JSON.parse(it.content_en).replace(/[\n\r]/g,'<p>')
			// 		// 	}else if(it.content !='null'&&it.content){
			// 		// 		content = JSON.parse(it.content).replace(/[\n\r]/g,'<p>')
			// 		// 	}else{
			// 		// 		content = ''
			// 		// 	}
			// 		// }else{
			// 		// content=this.lang == 'en-US'?it.content_en:it.content
			// 		if(it.content){
			// 			let acontent = JSON.parse(it.content)
			// 			if(!Array.isArray(acontent)){
			// 				content = [{desc: acontent}]
			// 			}else{
			// 				content = acontent
			// 			}
			// 		}
			// 		// }
			// 		if(it.pictures && it.pictures.length>0){
			// 			pic=it.pictures.split(',')
			// 		}
			// 		title = this.lang == 'en-US'?it.title_en :it.title
			// 		titile_comment = this.lang == 'en-US'?it.titile_comment_en :it.titile_comment
			// 		ob = {title:title,ltitle:ltitle,titile_comment:titile_comment,pic:pic,item_type:it.item_type,content:content}
			// 		items.push(ob)
			// 	}
			// 	return items
			// },
		},
		created() {
			if(!this.current){
				this.$router.push({ path:'/'})
			}
		},
		methods: {

		},
		mounted() {
			new this.$wow.WOW({live: false}).init();
		}
	}
</script>
